/* eslint-disable no-nested-ternary */
import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';
import ImpressionTracker from 'utils/components/impressionTracker';

import EntityName from 'components/Globals/EntityName';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import MapView, { MARKER_SIZES } from 'components/Globals/MapView';

import usePageContext from 'utils/hooks/usePageContext';
import { getLocation } from 'utils/globals';
import { getPrimaryProfession } from 'utils/artists';
import { getMediaSource } from 'utils/media';

import { TRACK_EVENTS, ENTITY_CARD_CLICK } from 'utils/tracking';
import { TP, ENTITY_TYPE } from 'constants/index';

import classes from './PreviewCard.module.scss';
import { GOOGLE_OLD_TRACKING_SERVICES } from '../Analytics/constants';

const VARIANT_SIZE = {
  LARGE: 'large',
};

const EntityTags = ({ tag, subTag }) => {
  if (!tag && !subTag) {
    return null;
  }
  return (
    <div className={classes.card__img_tagsContainer}>
      {tag && (
        <Typography size={11} weight="medium" textTransform="uppercase" className={classes.card__img_tag}>
          {tag}
        </Typography>
      )}
      {subTag && (
        <div className={classnames(classes.card__img_subtagContainer, classes.card__img_tag)}>
          <Typography size={10} className={classnames(classes.card__img_subtag)}>
            {subTag}
          </Typography>
        </div>
      )}
    </div>
  );
};

// TODO: This has some functions same as components/Globals/EntityCard/EntityCard.js need to refactor
const PreviewCard = ({
  entity,
  entityType,
  openNewTab,
  landscape = false,
  isLink = true,
  variant = 'native',
  trackingData,
  size,
  primaryProfession,
  preloadImage = false,
}) => {
  const { navigate } = usePageContext();
  const track = useTracking();
  const linkProps = navigate.getLinkProps({ entityType, entity, onlyLinkProps: true, rel: 'nofollow' });

  const isMapShown = useMemo(() => entity?.lat && entity?.lon, [entity?.lat, entity?.lon]);

  const transformations = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.ARTIST:
        return TRANSFORMATIONS.PROFILE_IMAGE;
      default:
        return TRANSFORMATIONS.ORG_LOGO;
    }
  }, [entityType]);

  const handleOnClick = useCallback(() => {
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: ENTITY_CARD_CLICK(ENTITY_TYPE.ARTIST),
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  }, [track]);

  const imgProps = useMemo(
    () => ({
      transformations,
      disableNextImage: true,
      useIntersectionObserver: true,
      width: landscape ? '100%' : size === VARIANT_SIZE.LARGE ? 149 : 109,
      height: size === VARIANT_SIZE.LARGE ? '220.5' : '161.32',
      alt: entity?.name,
      title: entity?.name,
      preload: preloadImage,
    }),
    [entity?.name, landscape, size, transformations, preloadImage],
  );

  const src = getMediaSource({
    id: entity?.id,
    file: { urls: entity?.image || entity?.logo },
  });

  const { subTitle, title, tag, subTag } = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPE.ARTIST: {
        return {
          label: `${TP}.m_ARTIST`,
          subTitle: entity?.headline ? entity?.headline : getPrimaryProfession(entity, primaryProfession),
        };
      }
      case ENTITY_TYPE.ORGANIZATION: {
        return {
          tag: entity?.organizationType?.name,
          subTitle: getLocation(entity),
        };
      }
      default: {
        return {};
      }
    }
  }, [entity, entityType, primaryProfession]);

  let content;
  if (src) {
    content = <Image src={src} {...imgProps} />;
  } else if (isMapShown) {
    content = (
      <MapView
        latitude={entity?.lat}
        longitude={entity?.lon}
        label={entity?.name}
        width={imgProps?.width}
        height={imgProps?.height}
        zoom={10}
        markerSize={MARKER_SIZES.TINY}
        withoutLink
      />
    );
  } else {
    content = (
      <ImageAvatar
        type="V4"
        name={entity?.name}
        width={size === VARIANT_SIZE.LARGE ? 149 : landscape ? 343 : 109}
        height="100%"
        fontSize={44}
        shape="rect"
      />
    );
  }

  const organisationType = entity?.organizationType?.slug;
  const completeTrackingData = {
    ...trackingData,
    entityId: entity?.id,
    entityName: entity?.name,
    entityType,
    meta: {
      ...(entity?.source_tag && { sourceTag: entity.source_tag }),
      ...(organisationType && { organisationType }),
      ...trackingData?.meta,
    },
  };

  return (
    <LinkButton
      styles={{ root: classnames(classes.link, { [classes.withoutLink]: !isLink }) }}
      variant={variant}
      title={title}
      isLink={isLink}
      trackingData={completeTrackingData}
      {...linkProps}
      {...(openNewTab && { target: '_blank' })}
      {...(ENTITY_TYPE.ARTIST === entityType && { onClick: handleOnClick })}
    >
      <ImpressionTracker data={completeTrackingData}>
        <div
          className={classnames(classes.card, {
            [classes.card__landscape]: landscape,
            [classes.card__large]: size === VARIANT_SIZE.LARGE,
          })}
        >
          <div className={classes.card__img}>
            {content}
            <EntityTags tag={tag} subTag={subTag} />
            <span className={classes.card__img_icon}>
              <SpriteIcon icon="chevron_right" className={classes.icon} size={16} />
            </span>
          </div>
          <div className={classes.card__textWrapper}>
            <Typography weight="bold" className={classnames(classes.title, { [classes.title__landscape]: landscape })}>
              <EntityName entityType={entityType} entity={entity} isRaw />
            </Typography>
            {subTitle && (
              <Typography size={12} className={classes.subtitle} color="secondary" truncate>
                {subTitle}
              </Typography>
            )}
          </div>
        </div>
      </ImpressionTracker>
    </LinkButton>
  );
};

export default PreviewCard;
